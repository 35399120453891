export const ACCOUNT_TYPE = {
  STUDENT: "Student",
  INSTRUCTOR: "Instructor",
  ADMIN: "Admin",
}

export const COURSE_STATUS = {
  DRAFT: "Draft",
  PUBLISHED: "Published",
}

export const genders = ["Male", "Female", "Non-Binary", "Prefer not to say", "Other"]
export const RAZORPAY_KEY = 'rzp_test_naUSZSoILkoyzC'