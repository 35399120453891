import React from 'react'
import { HiUsers } from 'react-icons/hi'
import { ImTree } from 'react-icons/im'

const Card = ({ info, setCurrentCard, currentCard }) => {
    return (
        <div className={`w-[360px] lg:w-[30%] ${currentCard === info.heading ? "bg-white shadow-[12px_12px_0_0] shadow-yellow-50" : "bg-richblack-800"} text-richblack-25 h-[300px] box-border cursor-pointer`} onClick={() => setCurrentCard(info?.heading)}>

            <div className="border-b-[2px] border-richblack-400 border-dashed h-[80%] p-6 flex flex-col gap-3">
                <div className={` ${currentCard === info?.heading && "text-richblack-800"} font-semibold text-[20px]`}>
                    {info?.heading}
                </div>
                <div className="text-richblack-400">{info?.description}</div>
            </div>

            <div className={`flex justify-between ${currentCard === info?.heading ? "text-blue-300" : "text-richblack-300"
                } px-6 py-3 font-medium`}>

                <div className="flex items-center gap-2 text-[16px]">
                    <HiUsers />
                    <p>{info?.level}</p>
                </div>

                <div className="flex items-center gap-2 text-[16px]">
                    <ImTree />
                    <p>{info?.lessionNumber} Lesson</p>
                </div>
            </div>
        </div>
    )
}

export default Card