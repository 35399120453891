import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BsFillCaretRightFill } from "react-icons/bs"
import { FaShareSquare } from "react-icons/fa"
import { ACCOUNT_TYPE } from '../../../utils/constants';
import toast from 'react-hot-toast';
import { addToCart } from '../../../Slices/cartSlice';
import copy from 'copy-to-clipboard';

const CourseDetailsCard = ({ course, setConfModal, handleBuyCourse }) => {
    const { user } = useSelector((state) => state.profile)
    const { token } = useSelector((state) => state.auth)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        thumbnail: ThumbnailImage,
        price: CurrentPrice,
        _id: courseId,
    } = course

    const handleAddToCart = () => {
        if (user && user?.accountType !== ACCOUNT_TYPE.STUDENT) {
            toast.error("Only Students can buy courses")
            return
        }

        if (token) {
            dispatch(addToCart(course))
            return
        }

        setConfModal({
            text1: "You are not logged in!",
            text2: "Please login to add To Cart",
            btn1Text: "Login",
            btn2Text: "Cancel",
            btn1Handler: () => navigate("/login"),
            btn2Handler: () => setConfModal(null),
        })
    }

    const handleShare = () => {
        copy(window.location.href)
        toast.success("Link Copied to Clipboard")
    }

    const formatItem = (item, i) => {
        const n = item.length
        console.log(item,n)
        
        if (i == 0)
            return item.substr(2, n-3)
        else if (item.at(-1) == ']')
            return item.substr(1, n - 3)
        else
            return item.substr(1,n-2)
    }

    return (
        <div className={`flex flex-col gap-4 rounded-md bg-richblack-700 p-4 text-richblack-5`}>
            <img
                src={ThumbnailImage}
                alt='course image'
                className="max-h-[300px] min-h-[180px] w-[400px] overflow-hidden rounded-2xl object-cover md:max-w-full"
            />

            <div className="px-4">
                <span className="space-x-3 pb-4 text-3xl font-semibold">
                    Rs. {CurrentPrice}
                </span>
            </div>
            <div className="flex flex-col gap-4">
                <button
                    className="yellowButton"
                    onClick={user && course.studentsEnrolled.includes(user._id) ? () => navigate("/dashboard/enrolled-courses") : handleBuyCourse}
                >
                    {
                        user && course.studentsEnrolled.includes(user._id)
                            ? "Go to Course"
                            : "Buy Now"
                    }
                </button>

                {(!course.studentsEnrolled.includes(user?._id)) && (
                    <button onClick={handleAddToCart} className='blackButton'>
                        Add to Cart
                    </button>
                )}
            </div>

            <div>
                <p className="pb-3 pt-6 text-center text-sm text-richblack-25">
                    30-Day Money-Back Guarantee
                </p>
            </div>

            <div>
                <p className={`my-3 text-xl font-semibold `}>Additional Information : </p>

                <div className='flex flex-col gap-3 text-sm text-caribbeangreen-100'>
                    {
                        course.instructions.at(0).split(',').map((item, i) => (
                            <p className='flex gap-2' key={i}>
                                <BsFillCaretRightFill />
                                <span>{formatItem(item, i)}</span>
                            </p>
                        ))
                    }

                </div>
            </div>

            <div className='text-center'>
                <button className="mx-auto flex items-center gap-2 py-6 text-yellow-100 " onClick={handleShare}>
                    <FaShareSquare size={15} /> Share
                </button>
            </div>
        </div>
    )
}

export default CourseDetailsCard